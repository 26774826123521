(function () {
  'use strict';

  class DiscussionsFlowCtrl {
    constructor($mdDialog, DiscussionsFlow, Modules, Discussions) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Modules = Modules;
      vm.Discussions = Discussions;
      vm.discussion = DiscussionsFlow.getObj();
      vm.complete = vm.discussion;
      if (vm.discussion.id) {
        vm.Discussions.discussionView({id: vm.discussion.id})
          .then((data)=> {
            vm.complete = data.discussion;
          });
      }
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        if (vm.discussion.id) {
          vm.Discussions.discussionEdit(vm.complete)
            .then((data)=> {
              vm.hide(data);
            });
        } else {
          vm.Discussions.discussionsCreate(vm.complete)
            .then((data)=> {
              vm.hide(data);
            });
        }
      }
    }
    deleteDiscussion(ev) {
      let vm = this;
      if (vm.complete.entries.found > 0) {
        const confirm = vm.$mdDialog.confirm()
          .title('Warning')
          .textContent(`Deleting this discussion will remove ${vm.complete.entries.found} discussion entries.  Are you sure you want to do this?`)
          .ariaLabel('Warning')
          .targetEvent(ev)
          .ok('Delete')
          .cancel('Cancel')
          .multiple(true);

        vm.$mdDialog.show(confirm).then(function () {
          vm.Discussions.discussionDelete({id: vm.discussion.id})
            .then((data)=> {
              vm.hide(data);
            });
        });
      } else {
        vm.Discussions.discussionDelete({id: vm.discussion.id})
          .then((data)=> {
            vm.hide(data);
          });
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('DiscussionsFlowCtrl', DiscussionsFlowCtrl);
}());
